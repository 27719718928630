<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <g clip-path="url(#clip0_13074_9995)">
      <path
        d="M14.0002 25.6667C7.55666 25.6667 2.3335 20.4435 2.3335 14C2.3335 7.55654 7.55666 2.33337 14.0002 2.33337C20.4437 2.33337 25.6668 7.55654 25.6668 14C25.6668 20.4435 20.4437 25.6667 14.0002 25.6667ZM11.3285 22.9449C10.1775 20.5034 9.51068 17.862 9.365 15.1667H4.73916C4.96618 16.9621 5.70974 18.6529 6.87949 20.0337C8.04925 21.4145 9.59485 22.4258 11.3285 22.9449ZM11.7018 15.1667C11.878 18.0122 12.6912 20.685 14.0002 23.044C15.3445 20.6227 16.1298 17.9311 16.2985 15.1667H11.7018ZM23.2612 15.1667H18.6353C18.4896 17.862 17.8228 20.5034 16.6718 22.9449C18.4055 22.4258 19.9511 21.4145 21.1208 20.0337C22.2906 18.6529 23.0341 16.9621 23.2612 15.1667ZM4.73916 12.8334H9.365C9.51068 10.1381 10.1775 7.49667 11.3285 5.05521C9.59485 5.57423 8.04925 6.58559 6.87949 7.96639C5.70974 9.34719 4.96618 11.038 4.73916 12.8334ZM11.703 12.8334H16.2973C16.129 10.0691 15.3441 7.3775 14.0002 4.95604C12.6558 7.3774 11.8705 10.069 11.7018 12.8334H11.703ZM16.6718 5.05521C17.8228 7.49667 18.4896 10.1381 18.6353 12.8334H23.2612C23.0341 11.038 22.2906 9.34719 21.1208 7.96639C19.9511 6.58559 18.4055 5.57423 16.6718 5.05521Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13074_9995">
        <rect width="28" height="28" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

